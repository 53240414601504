import { Grid, Paper, Skeleton, styled } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  boxShadow: "none",
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const VitrineLoading = () => {
  return (
    <Grid
      container
      xs={12}
      md={8}
      sm={8}
      lg={4}
      xl={3}
      sx={{
        margin: "auto",
        mt: "3%",
        alignItems: "center",
        maxWidth: "460px !important",
      }}
    >
      <Grid item xs={12} md={12} lg={12}>
        <Item>
          <Skeleton width="auto" height={50} animation="wave" />
        </Item>
      </Grid>

      <Grid item xs={7} md={7} lg={7}>
        <Item>
          <Skeleton width="auto" height={50} animation="wave" />
        </Item>
      </Grid>
      <Grid item xs={5} md={5} lg={5}>
        <Item>
          <Skeleton width="auto" height={50} animation="wave" />
        </Item>
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <Item>
          <Skeleton width="auto" height={50} animation="wave" />
        </Item>
      </Grid>
      <Grid item xs={7} md={7} lg={7}>
        <Item>
          <Skeleton width="auto" height={50} animation="wave" />
        </Item>
      </Grid>
      <Grid item xs={5} md={5} lg={5}>
        <Item>
          <Skeleton width="auto" height={50} animation="wave" />
        </Item>
      </Grid>
      <Grid item sx={{ mt: 2, mb: 2 }} xs={12} md={12} lg={12}>
        <Item>
          <Skeleton width="auto" height={50} animation="wave" />
        </Item>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Item>
          <Skeleton width="auto" height={50} animation="wave" />
        </Item>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Item>
          <Skeleton width="auto" height={50} animation="wave" />
        </Item>
      </Grid>
    </Grid>
  );
};

export default VitrineLoading;
