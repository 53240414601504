import { useState, useEffect, Fragment } from "react";
import { getDadosBasicos } from "../../../api/Cadastro";
import CampoTexto from "../../Campos/CampoTexto";
import useStyle from "./styles";
import DadosPessoaFisica from "./DadosPessoaFisica";
import DadosPessoaJuridica from "./DadosPessoaJuridica";
import DadosPessoaEstrangeira from "./DadosPessoaEstrangeira";
import { Grid, Skeleton, Typography } from "@mui/material";
import Subtitulo from "../../Textos/Subtitulo";
import CustomGrid from "../../GridCadastroCustom";
import Alert from "@mui/material/Alert";
import InputMask from "react-input-mask";
import TextField from "@mui/material/TextField";
import Indicações from "./Indicacoes";

export default function DadosBasicos({
  idCliente,
  setDada,
  isLoading,
  idPerfilLimiteOperacional,
  setNome,
  setDisabledButton = null,
}) {
  const classes = useStyle();
  const [dadosForm, setDadosForm] = useState({});
  const [haveNome, setHaveNome] = useState(true);

  useEffect(() => {
    //Força o reloading dos campos
    setDadosForm(() => ({
      ...dadosForm,
      dadosPessoaFisica: null,
      dadosPessoaJuridica: null,
      dadosPessoaEstrangeira: null,
    }));
    init();
  }, [idPerfilLimiteOperacional]);

  const init = async () => {
    const response = await getDadosBasicos(
      idCliente,
      idPerfilLimiteOperacional
    );
    let campoNome = response?.campos?.find(
      ({ propriedade }) => propriedade === "Nome"
    );

    setHaveNome(() => !!campoNome.valor);

    setDadosForm(response);
    setDada(response);
  };

  const handlerChangeValue = (e) => {
    if (e.target.name === "Nome ") {
      setNome(e.target.value);
    }

    const newValue = dadosForm.campos.map((c) => {
      if (c.propriedade === e.target.name)
        return { ...c, ["valor"]: e.target.value };
      else return c;
    });

    setDadosForm({ ...dadosForm, ["campos"]: newValue });
    setDada({ ...dadosForm, ["campos"]: newValue });
  };

  return (
    <>
      <div className={classes.box}>
        {dadosForm.enableIndicacoes && (
          <>
            <Indicações
              classes={classes}
              dadosForm={dadosForm}
              setForm={setDadosForm}
              setData={setDada}
              isLoading={isLoading}
              setDisabledButton={setDisabledButton}
              idPerfilLimiteOperacional={idPerfilLimiteOperacional}
            />
          </>
        )}

        {isLoading ? (
          <Skeleton animation="wave" width={"10%"} height={30} />
        ) : (
          <Subtitulo
            sx={{
              fontWeight: 500,
              clear: "both",
              mt: 3,
              mb: 2,
              fontSize: 15,
            }}
            color="primary"
            label="Dados Pessoais"
          />
        )}
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 1, md: 2 }}
          sx={{ mb: 2 }}
        >
          {!haveNome && (
            <>
              {isLoading ? (
                <Skeleton animation="wave" height={50} />
              ) : (
                <>
                  <Alert
                    className={classes.Alert}
                    severity="info"
                    sx={{ width: "100%" }}
                  >
                    <Typography sx={{ fontSize: 13 }}>
                      Escreva o seu nome completo exatamente como está no seu
                      documento de identidade.
                    </Typography>
                  </Alert>
                </>
              )}
            </>
          )}

          {dadosForm &&
            dadosForm.campos &&
            dadosForm.campos.map((c, i, array) => (
              <Fragment key={i}>
                <CustomGrid index={i} array={array} md={6}>
                  {c.tipoControle === "Texto" &&
                    c.tipoMascara === null &&
                    (isLoading ? (
                      <Skeleton animation="wave" width={"100%"} height={60} />
                    ) : (
                      <>
                        <CampoTexto
                          key={i}
                          enable={c.enable}
                          required={c.obrigatorio}
                          variant="filled"
                          size="medium"
                          type="text"
                          fullWidth={true}
                          defaultValue=" "
                          label={c.label}
                          name={c.propriedade}
                          value={c.valor}
                          handlerChange={handlerChangeValue}
                          className={classes.campo}
                          autoFocus
                          maximoDigitos={c.maxLength}
                        />
                      </>
                    ))}

                  {c.tipoMascara === "Celular" &&
                    (isLoading ? (
                      <Skeleton animation="wave" width={"100%"} height={60} />
                    ) : (
                      <>
                        <InputMask
                          onChange={handlerChangeValue}
                          mask={"(99) 99999-9999"}
                          value={c.valor}
                          enable={c.enable}
                          maskChar="_"
                        >
                          {() => (
                            <TextField
                              spellCheck="false"
                              required={c.obrigatorio}
                              fullWidth={true}
                              label={c.label}
                              maxLength={c.maxLength}
                              name={c.propriedade}
                              value={c.valor}
                              size="medium"
                              className={classes.campo}
                              onChange={handlerChangeValue}
                            />
                          )}
                        </InputMask>
                      </>
                    ))}
                  {c.tipoMascara === "TelefoneFixo" &&
                    (isLoading ? (
                      <Skeleton animation="wave" width={"100%"} height={60} />
                    ) : (
                      <>
                        <InputMask
                          onChange={handlerChangeValue}
                          mask={"(99) 9999-9999"}
                          value={c.valor}
                          enable={c.enable}
                          maskChar="_"
                        >
                          {() => (
                            <TextField
                              spellCheck="false"
                              required={c.obrigatorio}
                              fullWidth={true}
                              label={c.label}
                              maxLength={c.maxLength}
                              // isLoading={true}
                              name={c.propriedade}
                              value={c.valor}
                              size="medium"
                              className={classes.campo}
                              onChange={handlerChangeValue}
                            />
                          )}
                        </InputMask>
                      </>
                    ))}

                  {c.tipoMascara === "TelefoneInternacional" &&
                    (isLoading ? (
                      <Skeleton animation="wave" width={"100%"} height={60} />
                    ) : (
                      <>
                        <InputMask
                          onChange={handlerChangeValue}
                          mask={"+999 999 999 999"}
                          value={c.valor}
                          enable={c.enable}
                          maskChar="_"
                        >
                          {() => (
                            <TextField
                              spellCheck="false"
                              required={c.obrigatorio}
                              fullWidth={true}
                              label={c.label}
                              maxLength={c.maxLength}
                              // isLoading={true}
                              name={c.propriedade}
                              value={c.valor}
                              size="medium"
                              className={classes.campo}
                              onChange={handlerChangeValue}
                            />
                          )}
                        </InputMask>
                      </>
                    ))}

                  {c.tipoMascara === "CPF" &&
                    (isLoading ? (
                      <Skeleton animation="wave" width={"100%"} height={60} />
                    ) : (
                      <>
                        <InputMask
                          onChange={handlerChangeValue}
                          mask={"999.999.999-99"}
                          value={c.valor}
                          disabled={!c.enable}
                          maskChar="_"
                        >
                          {() => (
                            <TextField
                              spellCheck="false"
                              required={c.obrigatorio}
                              fullWidth={true}
                              label={c.label}
                              maxLength={c.maxLength}
                              // isLoading={true}
                              disabled={
                                c.propriedade === "NumeroDocumentoIdentificacao"
                                  ? true
                                  : c.enable
                              }
                              name={c.propriedade}
                              value={c.valor}
                              size="medium"
                              className={classes.campo}
                              onChange={handlerChangeValue}
                            />
                          )}
                        </InputMask>
                      </>
                    ))}

                  {c.tipoMascara === "CNPJ" &&
                    (isLoading ? (
                      <Skeleton animation="wave" width={"100%"} height={60} />
                    ) : (
                      <>
                        <InputMask
                          onChange={handlerChangeValue}
                          mask={"99..999.999/9999-99"}
                          value={c.valor}
                          disabled={!c.enable}
                          maskChar="_"
                        >
                          {() => (
                            <TextField
                              spellCheck="false"
                              required={c.obrigatorio}
                              fullWidth={true}
                              label={c.label}
                              maxLength={c.maxLength}
                              // isLoading={true}
                              disabled={
                                c.propriedade === "NumeroDocumentoIdentificacao"
                                  ? true
                                  : c.enable
                              }
                              name={c.propriedade}
                              value={c.valor}
                              size="medium"
                              className={classes.campo}
                              onChange={handlerChangeValue}
                            />
                          )}
                        </InputMask>
                      </>
                    ))}
                </CustomGrid>
              </Fragment>
            ))}

          {dadosForm.dadosPessoaFisica &&
            dadosForm.dadosPessoaFisica.campos && (
              <>
                <DadosPessoaFisica
                  classes={classes}
                  dadosForm={dadosForm}
                  setForm={setDadosForm}
                  setData={setDada}
                  isLoading={isLoading}
                  setDisabledButton={setDisabledButton}
                  idPerfilLimiteOperacional={idPerfilLimiteOperacional}
                />
              </>
            )}

          {dadosForm.dadosPessoaJuridica &&
            dadosForm.dadosPessoaJuridica.campos && (
              <>
                <DadosPessoaJuridica
                  classes={classes}
                  dadosForm={dadosForm}
                  setForm={setDadosForm}
                  setData={setDada}
                  isLoading={isLoading}
                  setDisabledButton={setDisabledButton}
                />
              </>
            )}

          {dadosForm.dadosPessoaEstrangeira && (
            <DadosPessoaEstrangeira
              classes={classes}
              dadosForm={dadosForm}
              setForm={setDadosForm}
              setData={setDada}
              isLoading={isLoading}
              setDisabledButton={setDisabledButton}
            />
          )}
        </Grid>
      </div>
    </>
  );
}
