import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  card: {
    borderWidth: "1px !important",
    borderRadius: "10px !important",
    maxWidth: '32rem',
    boxShadow: '0px 0px 4.5px 1px rgba(0,0,0,.3)',
  },

  qrCode: {
    background: "#fff",
    height: theme.spacing(20),
    marginTop: 10,
  },
  listaInformacoes: {
    listStyle: "none",
    padding: 0,
  },

  itemList: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "100vw",
    padding: "10px",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-between",
      alignItems: "center",
      maxWidth: "100vw",
    },
  },
  iconButton: {
    height: 22,
  },
}));

export default useStyle;
