import { useState } from "react";
import BandeiraBrasil from "../../../../assets/images/bandeiras/brasil.png";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {
  Grid,
  Box,
  Typography,
  FormControl,
  useTheme,
  Link,
  Skeleton,
  Alert,
  Stack,
  AlertTitle,
} from "@mui/material";
import { Info as InfoIcon } from "@mui/icons-material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  ButtonAcaoGrande,
  Termo,
  Seletor,
  CampoOperacao,
  TooltipInformacao,
  PositionTooltip,
  CampoImg,
  Modal,
} from "../../..";
import SeletorMoeda from "../../../Campos/SeletorMoeda";
import { Style } from "./styles";
import useAlert from "../../../../hooks/useAlert/index";
import { formatMoneyInStringForm, verificaPedidoDiferente } from "../../../../util/helpers";
import Tarifa from "../../Tarifa";
import useLojaFechada from "../../../../hooks/useLojaFechada";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { GETTERMORESPONSABILIDADE } from "../../../../util/typesReactQuery";
import { getTermoResponsabilidade } from "../../../../api/TermoResponsabilidade";
import parseHtml from "html-react-parser";
import usePedido from "../../../../hooks/usePedido";
import ButtonAcaoGrandeOutlined from "../../../Botoes/ButtonAcaoGrande copy";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  boxShadow: "none",
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export function VitrineBase({
  vitrine,
  isRecebimento = false,
  itemSelected,
  onChangeItem,
  onChangeValorMe,
  onAddCart,
  itemCarrinho,
  isErrorTermo = false,
  onChangeFinalidade,
  onChangeTermo,
  isTermoResponsabilidadeAceite,
  isTermoUsoAceite,
  onChangeTermoResponsabilidade,
  loadingAddcart = false,
  onBlurValueChangeItem,
  disabledButton,
  isErrorTermoResponsabilidade = false,
  isLoadingTaxa = false,
}) {
  const classes = Style();
  const theme = useTheme();
  const {
    showAlertError,
    showAlertInfo,
    showAlertSuccess,
    showAlertWarning,
  } = useAlert();
  const [modalShow, setModalShow] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [termoResponsabilidade, setTermoResponsabilidade] = useState("");
  const { itensPedido: listItemCarrinho } = usePedido();
  const descricaoFinalidade = vitrine?.finalidades?.find(finalidade => finalidade.id == itemCarrinho.idFinalidade)?.descricaoFuncionaHtml ?? "";


  const handlerTermoAceite = (aceito) => {
    onChangeTermo(aceito);
  };

  const handlerTermoAceiteResponsabilidade = (aceito) => {
    onChangeTermoResponsabilidade(aceito);
  };

  useQuery([GETTERMORESPONSABILIDADE], () => getTermoResponsabilidade(), {
    onSuccess: (data) => {
      if (!data) {
        showAlertError(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: Não foi encontrado nenhum termo responsabilidade - RADLH`
        );
        return;
      }
      if (data.errorMessage) {
        showAlertError(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${data.errorMessage} - RHDVN`
        );
        return;
      }

      setTermoResponsabilidade(data.data);
    },
    onError: (error) => {
      showAlertError(
        `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${error} - RHK4L`
      );
    },
    refetchOnWindowFocus: false,
  });



  const handleManipulationEventAdd = (event) => {
    let text = verificaPedidoDiferente(itemCarrinho, listItemCarrinho);

    if (!!text) {
      setModalMessage(text);
      setModalShow(true)
      return;
    }

    onAddCart(event);
  };

  const handlerModalOptions = (res) => (event) => {
    if (res === "sim")
      onAddCart(event);
    setModalShow(false);
  };


  return (
    <>
      <Modal
        show={modalShow}
        close={() => setModalShow(false)}
        sx={{ border: "" }}
      >
        <ErrorOutlineIcon
          color="primary"
          fontSize="large"
          sx={{ margin: "auto", width: "100%" }}
        />
        <Typography
          sx={{
            display: "flex",
            alignContent: "center",
            gap: ".3rem",
            textAlign: "center",
            mb: 3,
          }}
        >
          {modalMessage}
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "space-around" }}>
          <ButtonAcaoGrande
            sx={{ zIndex: theme.zIndex.mobileStepper, width: "40%" }}
            color="success"
            texto="Sim"
            loading={loadingAddcart}
            Handle={handlerModalOptions("sim")}
          />
          <ButtonAcaoGrandeOutlined
            sx={{ zIndex: theme.zIndex.mobileStepper, width: "40%" }}
            color="error"
            texto="não"
            variant="outlined"
            loading={loadingAddcart}
            Handle={handlerModalOptions("não")}
          />
        </Box>
      </Modal>
      <Grid
        container
        xs={12}
        md={8}
        sm={8}
        lg={4}
        xl={3}
        sx={{
          margin: "auto",
          mt: "1%",
          p: 2,
          alignItems: "center",
          maxWidth: "460px !important",
        }}
      >
        <Grid item xs={12} md={12} lg={12}>
          <Item>
            <FormControl className={classes.FormControl} size="small">
              {vitrine && vitrine.finalidades ? (
                <Seletor
                  disabled={isRecebimento}
                  setSelect={onChangeFinalidade}
                  list={vitrine.finalidades}
                  size="small"
                  label="Escolha sua finalidade"
                  variant="outlined"
                  fullWidth={true}
                  value={itemCarrinho.idFinalidade}
                />
              ) : (
                <Skeleton width="auto" height={50} animation="wave" />
              )}
            </FormControl>
            {
              !!descricaoFinalidade &&
              <Stack sx={{ width: '100%', mt: theme.spacing(2) }} spacing={2}>
                <Alert severity="info">
                  <AlertTitle>Informações sobre a finalidade</AlertTitle>
                  <Typography sx={{ fontSize: '10px !important' }}>{parseHtml(descricaoFinalidade)}</Typography>
                </Alert>
              </Stack>
            }

          </Item>
        </Grid>

        <Grid item xs={7} md={7} lg={7}>
          <Item>
            <CampoOperacao
              className={classes.TextField}
              casasDecimais={2}
              prefix={""}
              size="normal"
              fullwidth
              enable={!isRecebimento}
              variant="outlined"
              value={itemSelected.valorMe}
              onChange={onChangeValorMe}
              onBlur={onBlurValueChangeItem}
            />
          </Item>
        </Grid>
        <Grid item xs={5} md={5} lg={5}>
          <Item sx={{ display: "flex" }}>
            {vitrine &&
              vitrine.itensVitrine &&
              vitrine.itensVitrine.length > 0 && (
                <FormControl sx={{ minWidth: "100%" }}>
                  <SeletorMoeda
                    disabled={isRecebimento}
                    list={vitrine.itensVitrine}
                    value={itemCarrinho.idItemVitrine}
                    setSelect={onChangeItem}
                    size="normal"
                    fullWidth={true}
                  />
                </FormControl>
              )}
          </Item>
        </Grid>

        <Grid sx={{ mb: ".8rem" }} item xs={12} md={12} lg={12}>
          <Item>
            <Tarifa
              casasDecimais={itemSelected.moeda.numeroCasaDecimais}
              tarifaAdministrativa={itemCarrinho.tarifaAdministrativa}
              impostos={itemCarrinho.impostos}
              simbolo={itemSelected.moeda.simbolo}
              taxa={itemCarrinho.valorTaxaAplicada}
              isLoading={isLoadingTaxa}
            />
          </Item>
        </Grid>
        <Grid item xs={7} md={7} lg={7}>
          <Item>
            <CampoOperacao
              className={classes.TextField}
              label="Valor em reais"
              prefix="R$ "
              value={formatMoneyInStringForm(itemCarrinho.valorTotalMN)}
              id="reddit-input"
              variant="outlined"
              size="normal"
              fullwidth
              enable={false}
              casasDecimais={2}
            />
          </Item>
        </Grid>
        <Grid item xs={5} md={5} lg={5}>
          <Item sx={{ pb: 0 }}>
            {" "}
            <CampoImg
              sx={{ mt: 0 }}
              disabled
              fullWidth={false}
              className={classes.select}
              size="normal"
              value="BRL"
              id="reddit-input"
              variant="outlined"
              img={BandeiraBrasil}
            ></CampoImg>
          </Item>
        </Grid>
        <Grid sx={{ mt: 1, mb: 1 }} xs={12} md={12} lg={12}>
          {" "}
          <Typography
            sx={{ mt: 0, mb: 0, ml: 2 }}
            className={classes.dFlex}
            variant="body1"
          >
            <TooltipInformacao
              positionStart={PositionTooltip.left}
              descricao="O Valor Efetivo Total (VET) representa o custo de uma operação de câmbio em reais por moeda estrangeira, englobando a taxa de câmbio, as tarifas e tributos incidentes sobre essa operação."
            >
              <InfoIcon sx={{ fontSize: 20, mr: 1 }} color="primary"></InfoIcon>
            </TooltipInformacao>
            Com isso, 1 {itemSelected.moeda.simbolo} = R${" "}
            {formatMoneyInStringForm(itemCarrinho.valorVet, 5)} (VET)
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sx={{ mt: 1 }}>
          <Box
            className={
              isTermoResponsabilidadeAceite
                ? [classes.AlertaTermos, classes.isAceiteResponsabilidade]
                : isErrorTermoResponsabilidade
                  ? [classes.AlertaTermos, classes.isErrorTermo]
                  : classes.AlertaTermos
            }
          >
            <Termo
              modalText={termoResponsabilidade?.conteudoHtml}
              isAceite={isTermoResponsabilidadeAceite}
              setIsAceite={handlerTermoAceiteResponsabilidade}
              titulo="Termos de Responsabilidade"
            >
              <Typography color="text.secondary" variant="subtitle1">
                {" "}
                Aceitar nossos
                <Link style={{ cursor: "pointer" }}>
                  {" "}
                  Termos de Responsabilidade{" "}
                </Link>
              </Typography>
            </Termo>
          </Box>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Box
            className={
              isTermoUsoAceite
                ? [classes.AlertaTermos, classes.isAceiteResponsabilidade]
                : isErrorTermo
                  ? [classes.AlertaTermos, classes.isErrorTermo]
                  : classes.AlertaTermos
            }
          >
            <Termo
              modalText={vitrine?.termoUso}
              isAceite={isTermoUsoAceite}
              titulo="Termos de Uso"
              setIsAceite={handlerTermoAceite}
            >
              <Typography color="text.secondary" variant="subtitle1">
                {" "}
                Ao continuar, você aceita os
                <Link style={{ cursor: "pointer" }}> Termos de Uso </Link>da
                nossa empresa brasileira autorizada pelo Banco Central do
                Brasil.
              </Typography>
            </Termo>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <ButtonAcaoGrande
            sx={{ zIndex: theme.zIndex.mobileStepper }}
            texto="Continuar"
            loading={loadingAddcart}
            disabled={disabledButton}
            Handle={handleManipulationEventAdd}
          />
        </Grid>
      </Grid>
    </>
  );
}
