import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { ORDEMPAGAMENTO } from "../../util/typesReactQuery";
import { getByIdCliente } from "../../api/OrdemPagamento";
import useAlert from "../../hooks/useAlert";
import { useAuth } from "../../hooks/AuthContext";
import Titulo from "../Textos/Titulo";
import ButtonAcaoGrande from "../Botoes/ButtonAcaoGrande";
import useStyle from "./style";
import { Box, Skeleton, Typography } from "@mui/material";
import { formatMoneyInString } from "../../util/helpers";
import theme from "../Paleta/theme";
import Subtitulo from "../Textos/Subtitulo";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import { PositionTooltip, TooltipInformacao } from "../TooltipInformacao";
import InfoIcon from "@mui/icons-material/Info";
import useLojaFechada from "../../hooks/useLojaFechada";

const OrdemPagamento = ({ itensVitrine, isLoading, handlerSelectOrdem }) => {
  const { showAlertError } = useAlert();
  const { user } = useAuth();
  const classes = useStyle();
  const [ordemPagamento, setOrdemPagamento] = useState(null);
  const { lojaFechada, errors: errorsLojaFechada } = useLojaFechada();
  const navigation = useNavigate();

  useQuery([ORDEMPAGAMENTO], () => getByIdCliente(user.id), {
    onSuccess: (data) => {
      if (!!!data || !!data.errorMessage) {
        console.error(`${data.errorMessage} - R2BA3`);
        showAlertError(
          `Ops, algo deu errado, por favor informe o suporte o seguinte error: Não foi possivel encontrar nenhum canal exterior - R979B `
        );
      }

      setOrdemPagamento(data?.data);
    },
    onError: (error) => {
      console.error(` ${error} - R5A2F`);
      showAlertError(
        `Ops, algo deu errado, por favor informe o suporte o seguinte error: ${error} - R1AFB `
      );
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (lojaFechada && errorsLojaFechada) {
      console.error("Primecase Error - ", errorsLojaFechada);
      showAlertError(errorsLojaFechada);
    }
  }, errorsLojaFechada);

  const selectedHendlerOrdemPagamento = (id) => (event) => {
    event.preventDefault();

    if (lojaFechada && lojaFechada?.isFechada) navigation("/LojaFechada");

    handlerSelectOrdem(id);
  };

  const ListOrdem = ({ ordem }) => {
    var item = itensVitrine?.find((i) => i.moeda.id === ordem.moeda.id);

    return (
      <>
        {item && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%  ",
              p: 0.3,
            }}
          >
            <Box>
              <Subtitulo
                sx={{ width: "100% !important", alignItems: "center" }}
              >
                <p
                  style={{
                    width: "100% !important",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span>
                    <img
                      src={`/images/Bandeiras/${item?.moeda?.codigoBacen}.svg`}
                      loading="lazy"
                      className={classes.Bandeira}
                      alt="bandeira da moeda"
                    />
                  </span>
                  <Typography sx={{ fontWeight: "bold", color: "black" }}>
                    {" "}
                    {item?.moeda?.simbolo} -{" "}
                    {formatMoneyInString(ordem?.valor, item?.moeda?.simbolo)}
                  </Typography>
                </p>

                <Typography
                  sx={{
                    display: "flex",
                    mr: 1,
                    mt: 1,
                    fontWeight: "500",
                    color: "#555",
                    textTransform: "capitalize !important",
                  }}
                >
                  Ordem: {ordem.codigoOrdem}
                </Typography>
              </Subtitulo>
            </Box>

            <ArrowForwardIosIcon className={classes.iconSelected} />
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        <Skeleton height={50} animation="wave" width={"50%"} />
      ) : (
        <Box sx={{ alignItems: "center" }}>
          <Titulo sx={{ fontSize: 20, color: "black", mb: 1 }}>
            Suas ordens de Pagamento
            <Tooltip title="Após a corretora receber a transferência pelos dados bancários disponibilizados nesta tela, você receberá uma ordem de pagamento e poderá concluir sua operação.">
              <IconButton>
                <HelpOutlineIcon fontSize="small" color="black" />
              </IconButton>
            </Tooltip>
          </Titulo>
          <Subtitulo sx={{ mb: 1 }}>
            Quando recebermos a transferência sua ordem aparecerá aqui, e você
            poderá concluir a operação selecionando a ordem.
          </Subtitulo>
        </Box>
      )}

      <Box className={classes.containerOrdem}>
        {!isLoading ? (
          !!ordemPagamento &&
          ordemPagamento.map((item, index) => (
            <ButtonAcaoGrande
              Handle={selectedHendlerOrdemPagamento(item.id)}
              key={index}
              variant="link"
              fullWidth={true}
              className={classes.ordemPagamento}
            >
              <ListOrdem ordem={item} />
            </ButtonAcaoGrande>
          ))
        ) : (
          <>
            <Skeleton height={50} animation="wave" width={"50%"} />
            <Skeleton height={50} animation="wave" width={"50%"} />
            <Skeleton height={50} animation="wave" width={"50%"} />
          </>
        )}

        {!isLoading && !!!ordemPagamento?.length && (
          <>
            <Box sx={{ mt: 5, pt: 8, margin: "auto" }}>
              <RemoveShoppingCartIcon
                color="primary"
                sx={{
                  fontSize: 50,
                  textAlign: "center",
                  margin: "auto",
                  width: "100%",
                }}
              />
              <Subtitulo sx={{ mt: 1, textAlign: "center" }}>
                Você não possui nenhuma ordem de pagamento.<br></br>
              </Subtitulo>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default OrdemPagamento;
