import { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Skeleton from "@mui/material/Skeleton";
import { Link, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Hidden from "@mui/material/Hidden";
import Copyright from "../../components/Copyright";
import EspacoMenuLateralEsquerdo from "../../components/EspacoMenuLateralEsquerdo";
import EspacoConteudoLateralDireito from "../../components/EspacoConteudoLateralDireito";
import ButtonAcaoGrande from "../../components/Botoes/ButtonAcaoGrande";
import CodigoAutenticacao from "../../components/CodigoAutenticacao";
import { useAuth } from "../../hooks/AuthContext";
import useAlert from "../../hooks/useAlert";
import { CampoPassword, Rodape, LogoEmpresa } from "../../components";
import ButtonVoltar from "../../components/Botoes/ButtonVoltar/index";
import useStyles from "./styles";
import TiposErrosRedirecionado from "../../util/typesErrorLogin";
import {
  ClienteSolicitaAutenticacao,
  clienteLogin,
} from "../../api/ContaCliente";

import useSeoConfig from "../../hooks/useSeoConfig";

export default function Login() {
  const { signIn } = useAuth();
  // valores iniciais do forms
  const initialState = {
    googleId: "",
    email: "",
    senha: "",
    idHotsite: 0,
  };

  const { showAlertSuccess, showAlertError } = useAlert();
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [codigoAutenticacao, setCodigoAutenticacao] = useState("");
  const { sendPageAtual, sendTagConversionLogin } = useSeoConfig();

  let params = useParams();

  // useEffect(() => {
  //   sendPageAtual("Login")
  // }, [])

  useEffect(() => {
    sendPageAtual("Login");
    if (
      params.error === TiposErrosRedirecionado.SemPerfilDisponivel.toString()
    ) {
      showAlertError(
        "Ops, você foi deslogado, pois o perfil de limite operacional não esta disponivel, por gentileza entrar em contato com a corretora!!"
      );
      return;
    }

    if (!!params.error) {
      showAlertError(params.error);
    }
  }, [params]);

  // autenticação
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const [openModalAutenticacao, setOpenModalAutenticacao] = useState(false);
  // const handleCloseModalAutenticacao = () => setOpenModalAutenticacao(false);
  const handleSubmitModalAutenticacao = async (e) => {
    setLoading(true);

    let loginForm = {
      ...formData,
      codigoAutenticacao,
    };

    const response = await clienteLogin(loginForm);

    if (!response.data.errorMessage) {
      signIn({
        token: response.data.data.token,
        user: response.data.data.user,
      });
      showAlertSuccess("Login efetuado com sucesso");
    } else {
      setErrors(response.data.errorMessage);
      showAlertError(response.data.errorMessage);
      setLoading(false);
    }
  };

  const handleChangeCodigoAutenticacao = (e) => {
    setCodigoAutenticacao(e.target.value);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const solicitaCodigo = async () => {
    setLoading(true);

    const response = await ClienteSolicitaAutenticacao(formData);

    if (!response) {
      setErrors("Loja em manutenção, por favor, tente novamente em breve");
      showAlertError("Loja em manutenção, por favor, tente em breve");
      setLoading(false);
    }

    if (!response.data.errorMessage) {
      showAlertSuccess("Código de autenticação enviado com sucesso");

      if (activeStep === 0) handleNext();
    } else {
      setErrors(response.data.errorMessage);
      showAlertError(response.data.errorMessage);
    }

    setLoading(false);
  };

  const handlerSubmit = async (e) => {
    e.preventDefault();
    solicitaCodigo();
    sendTagConversionLogin();
  };

  const classes = useStyles();
  return (
    <>
      <CssBaseline />

      {activeStep === 1 && (
        <Grid container component="main" className={classes.Container}>
          <Hidden smDown>
            <EspacoMenuLateralEsquerdo isDrawer={false} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Grid
                item
                md={11}
                xs={false}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <LogoEmpresa idHotsite={0} />
              </Grid>

              <Grid
                item
                md={1}
                sm={1}
                xs={false}
                sx={{
                  mx: 5,
                }}
              >
                <Copyright />
              </Grid>
            </EspacoMenuLateralEsquerdo>
          </Hidden>
          {/* MENU Esquerdo */}
          <EspacoConteudoLateralDireito
          
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Grid item xs={12}>
              <Hidden smUp>
                <Grid
                  container
                  md={12}
                  xs={false}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LogoEmpresa idHotsite={0} />
                </Grid>
              </Hidden>

              <Grid item className={classes.Item} lg={6}>
                <Box onClick={handleBack}>
                  <ButtonVoltar></ButtonVoltar>
                </Box>
                <CodigoAutenticacao
                  // openModal={openModalAutenticacao}
                  loading={loading}
                  handleSolicitaCodigoNovamente={solicitaCodigo}
                  handleClose={handleBack}
                  handleSubmit={handleSubmitModalAutenticacao}
                  handleChange={handleChangeCodigoAutenticacao}
                />
              </Grid>
            </Grid>

           
          </EspacoConteudoLateralDireito>
        </Grid>
      )}

      {activeStep === 0 && (
        <>
          <Grid container component="main" className={classes.Container}>
            <Hidden smDown>
              <EspacoMenuLateralEsquerdo isDrawer={false}>
                <Grid
                  item
                  md={11}
                  xs={false}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LogoEmpresa idHotsite={0} />
                </Grid>

                <Grid
                  item
                  md={1}
                  sm={1}
                  xs={false}
                  sx={{ mx: 5, }}
                >
                  <Copyright />
                </Grid>
              </EspacoMenuLateralEsquerdo>
            </Hidden>
            {/* MENU DIREITO */}
            <EspacoConteudoLateralDireito
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Grid item xs={12} style={{ marginTop: '-10rem' }}>
                <Hidden smUp>
                  <Grid
                    item
                    xs={12}
                    alignItems="center"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginRight: "20px",
                    }}
                    justifyContent="center"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        my: 3,
                        backgroundColor: "none",
                        alignItems: "center",
                      }}
                    >
                      <LogoEmpresa idHotsite={0} />
                    </Box>
                  </Grid>
                </Hidden>

                <Grid item className={classes.Item} lg={6} >
                  <Box
                    sx={{
                      mx: 4,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography className={classes.Titulo} variant="h5">
                      Olá, seja bem vindo
                    </Typography>

                    <Typography className={classes.Subtitulo} variant="body1">
                      Ainda não tem uma conta?
                      <Link className={classes.Link} to="/Cadastro">
                        Cadastre-se
                      </Link>
                    </Typography>

                    <Box
                      component="form"
                      onSubmit={handlerSubmit}
                      sx={{ mt: 1 }}
                    >
                      <FormControl
                        className={classes.FormControl}
                        variant="outlined"
                      >
                        <InputLabel htmlFor="outlined-adornment-email">
                          E-mail
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-email"
                          type={"text"}
                          value={formData.email}
                          disabled={loading}
                          required={true}
                          name="email"
                          onChange={handleChange}
                          label="Email"
                          error={
                            formData.email.length > 0 &&
                            formData.email.length <= 5
                          }

                        // errorMessage="É necessário informar o e-mail"
                        />
                      </FormControl>
                      <CampoPassword
                        name="senha"
                        label="Senha"
                        value={formData.senha}
                        loading={loading}
                        handleChange={handleChange}
                      />{" "}
                      <Grid item xs sx={{ mt: 3 }}>
                        <ButtonAcaoGrande
                          sx={{ mb: 2 }}
                          texto="ENTRAR"
                          loading={loading}
                        />
                      </Grid>
                      <Grid container>
                        <Grid item xs sx={{ mt: 3 }}></Grid>
                        <Grid item sx={{ mt: 2 }}>
                          {loading && <Skeleton width={150} height={40} />}
                          {!loading && (
                            <Link
                              className={classes.Link}
                              to="/RecuperarSenha"
                              variant="body2"
                            >
                              Esqueceu sua senha?
                            </Link>
                          )}
                        </Grid>
                      </Grid>
                      <br></br>
                      <br></br>
                      {/* {loading && <Skeleton fullWidth height={70} />}
                      {!loading &&
                      <ButtonGoogle />
                      } */}
                    </Box>
                  </Box>
                </Grid>
                {/* <Rodape /> */}
              </Grid>
            </EspacoConteudoLateralDireito>
          </Grid>
        </>
      )}
    </>
  );
}
