import CampoTexto from "../../../Campos/CampoTexto";
import CampoData from "../../../Campos/CampoData";
import Skeleton from "@mui/material/Skeleton";
import CampoCheck from "../../../Campos/CampoCheck";
import CampoNumber from "../../../Campos/CampoNumber";
import Seletor from "../../../Seletor";
import Grid from "@mui/material/Grid";
import CustomGrid from "../../../GridCadastroCustom";
import { useEffect } from "react";
import { formatMoneyToDecimal } from "../../../../util/helpers";
import { useAuth } from "../../../../hooks/AuthContext";
import useAlert from "../../../../hooks/useAlert";

const tiposRegimeTributario = [
  {
    id: 1,
    descricao: "Simples Nacional",
  },
  {
    id: 2,
    descricao: "Lucro Real",
  },
  {
    id: 3,
    descricao: "Lucro Presumido",
  },
];

const tiposFormaConstituicao = [
  {
    id: 1,
    descricao: "Sociedade em Comum",
  },
  {
    id: 2,
    descricao: "Sociedade em Conta de Participação",
  },
  {
    id: 3,
    descricao: "Sociedade Simples",
  },
  {
    id: 4,
    descricao: "Sociedade em Nome Coletivo",
  },
  {
    id: 5,
    descricao: "Sociedade em Comandita Simples",
  },
  {
    id: 6,
    descricao: "Sociedade Limitada",
  },
  {
    id: 7,
    descricao: "Sociedade Anônima",
  },
  {
    id: 8,
    descricao: "Sociedade em Comandita por Ações",
  },
  {
    id: 9,
    descricao: "Sociedade Cooperativa",
  },
  {
    id: 10,
    descricao: "Sociedades Coligadas",
  },
  {
    id: 11,
    descricao: "Não Informado",
  },
];

export default function DadosPessoaJuridica({
  classes,
  dadosForm,
  setForm,
  setData,
  isLoading,
  setDisabledButton,
}) {
  const { user, signOut } = useAuth();
  const { showAlertError } = useAlert();

  const formatDateValueInput = (value) => {
    if (value.includes("T")) {
      return value.split("T")[0];
    } else {
      return value;
    }
  };

  const handlerChangeValue = (e) => {
    const camposInternos = dadosForm.dadosPessoaJuridica.campos.map((c) => {
      if (c.propriedade === e.target.name)
        return { ...c, ["valor"]: e.target.value };
      else return c;
    });

    setForm((previewData) => {
      const newCliente = { ...previewData };
      newCliente.dadosPessoaJuridica["campos"] = camposInternos;
      return newCliente;
    });

    setData((previewData) => {
      const newCliente = { ...previewData };
      newCliente.dadosPessoaJuridica["campos"] = camposInternos;
      return newCliente;
    });
  };

  const handleChangeCheck = (e) => {
    const camposInternos = dadosForm.dadosPessoaJuridica.campos.map((c) => {
      if (c.propriedade === e.target.name)
        return { ...c, ["valor"]: e.target.checked };
      else return c;
    });

    setForm((previewData) => {
      const newCliente = { ...previewData };
      newCliente.dadosPessoaJuridica["campos"] = camposInternos;
      return newCliente;
    });

    setData((previewData) => {
      const newCliente = { ...previewData };
      newCliente.dadosPessoaJuridica["campos"] = camposInternos;
      return newCliente;
    });
  };

  const handlerChangeSelect = (name, value) => {
    setForm((previewData) => {
      const newCliente = { ...previewData };
      newCliente.dadosPessoaJuridica[name] = value;
      return newCliente;
    });

    setData((previewData) => {
      const newCliente = { ...previewData };
      newCliente.dadosPessoaJuridica[name] = value;
      return newCliente;
    });
  };

  const handlerChangeValueNumero = (value, propriedade) => {
    const { campos } = dadosForm.dadosPessoaJuridica;

    // Encontra o objeto com a propriedade 'label' igual ao valor fornecido
    const updatedObjects = campos.map((obj) => {
      if (obj.propriedade === propriedade) {
        return {
          ...obj,
          valor: formatMoneyToDecimal(value),
        };
      }
      return obj;
    });

    setForm((previewData) => {
      const newCliente = { ...previewData };
      newCliente.dadosPessoaJuridica["campos"] = updatedObjects;
      return newCliente;
    });
  };

  useEffect(() => {
    if (
      !dadosForm ||
      !dadosForm.dadosPessoaJuridica ||
      !dadosForm.dadosPessoaJuridica.campos ||
      dadosForm.dadosPessoaJuridica.campos.lenght <= 0
    ) {
      if (setDisabledButton) setDisabledButton(true);
      showAlertError(
        "Ops, esse perfil não possui dados o suficiente para ser utilizado,selecione outro perfil ou entre em contato com a corretora"
      );

      return;
    }
    if (setDisabledButton) setDisabledButton(false);
  }, [dadosForm?.dadosPessoaJuridica?.campos]);

  return (
    <>
      {dadosForm.dadosPessoaJuridica.campos &&
        dadosForm.dadosPessoaJuridica.campos.map((c, i, array) => (
          <CustomGrid key={`fg_${i}`} index={i} array={array} md={6}>
            <>
              {c.tipoControle === "Texto" &&
                c.tipoMascara === null &&
                (isLoading ? (
                  <Skeleton animation="wave" height={60} />
                ) : (
                  <>
                    <CampoTexto
                      key={`ct_${i}`}
                      enable={c.enable}
                      variant="filled"
                      size="medium"
                      fullWidth={true}
                      label={c.label}
                      name={c.propriedade}
                      maximoDigitos={c.maxLength}
                      value={c.valor ?? ""}
                      handlerChange={handlerChangeValue}
                      required={c.obrigatorio}
                      className={classes.campo}
                    />
                  </>
                ))}
              {c.tipoControle === "Check" &&
                (isLoading ? (
                  <Skeleton animation="wave" height={60} />
                ) : (
                  <CampoCheck
                    key={`cc_${i}`}
                    index={i}
                    enable={c.enable}
                    variant="filled"
                    size="medium"
                    fullWidth={false}
                    label="Sim"
                    descricao={c.label}
                    name={c.propriedade}
                    value={c.valor}
                    handleChangeCheck={handleChangeCheck}
                    className={classes.campo}
                  />
                ))}

              {(c.tipoControle === "Data" || c.tipoMascara === "Data") &&
                (isLoading ? (
                  <Skeleton animation="wave" height={55} />
                ) : (
                  <CampoData
                    key={`cd_${i}`}
                    index={i}
                    enable={c.enable}
                    size="medium"
                    label={c.label}
                    fullWidth={true}
                    name={c.propriedade}
                    value={formatDateValueInput(c.valor)}
                    onChange={handlerChangeValue}
                    className={classes.campo}
                    required={c.obrigatorio}
                  />
                ))}
              {c.tipoControle === "Numero" &&
                (isLoading ? (
                  <Skeleton animation="wave" height={60} />
                ) : (
                  <>
                    <CampoNumber
                      key={i}
                      enable={c.enable}
                      variant="outlined"
                      size="medium"
                      fullWidth
                      label={c.label}
                      name={c.propriedade}
                      value={c.valor}
                      onChange={handlerChangeValueNumero}
                      className={classes.campo}
                      maximoDigitos={c.maxLength}
                      required={c.obrigatorio}
                    />
                  </>
                ))}
            </>
          </CustomGrid>
        ))}

      {dadosForm.dadosPessoaJuridica.idFormaConstituicao > 0 && (
        <Grid item xs={6} md={6} sx={{ mt: 0 }}>
          {isLoading && (
            <Skeleton variant="rectangular" height={40} animation={false} />
          )}
          {!isLoading && (
            <Seletor
              setSelect={(e) => handlerChangeSelect("idFormaConstituicao", e)}
              list={tiposFormaConstituicao}
              size="medium"
              defaultValue="Formas de Constituição"
              label="Formas de Constituição"
              fullWidth={true}
              value={dadosForm.dadosPessoaJuridica.idFormaConstituicao}
            />
          )}
        </Grid>
      )}
      {dadosForm.dadosPessoaJuridica.idRegimeTributario > 0 && (
        <Grid item xs={6} md={6} sx={{ mt: 0 }}>
          {isLoading && (
            <Skeleton variant="rectangular" height={40} animation={false} />
          )}
          {!isLoading && (
            <Seletor
              setSelect={(e) => handlerChangeSelect("idRegimeTributario", e)}
              list={tiposRegimeTributario}
              size="medium"
              defaultValue="Regimes Tributários"
              label="Regimes Tributários"
              fullWidth={true}
              value={dadosForm.dadosPessoaJuridica.idRegimeTributario}
            />
          )}
        </Grid>
      )}
    </>
  );
}
